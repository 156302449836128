import React from 'react';
import RedirectTo from "../../components/shared/RedirectTo/RedirectTo";
import {careersEnabled, getLink, getRoutes} from "../../components/shared/routes";
import OfferPage from "../../components/shared/OfferPage/OfferPage";
import IOSLogo from "../../images/Careers/deeplai-career-ios-developer.svg";

const CareersIOSDevelopers = () => {
    const routes = getRoutes();

    if (!careersEnabled.list) {
        return <RedirectTo/>;
    }
    if (!careersEnabled.iOS) {
        return <RedirectTo url={getLink([routes.Careers])}/>;
    }

    return (

        <OfferPage
            image={IOSLogo}
            imageAlt={'deeplai career ios developer'}
            name={routes.CareersIOSDeveloper.pageTitle}
            scopeOfDuties={<><p>software implementation by specification</p><p>active participation in
                the creation of the concept and design of software</p><p>cooperation with other teams -
                testers, designers, etc ...</p><p>cooperation in implementing and maintaining
                application with customers</p></>}
            ourRequirements={<>
                <span style={{fontWeight: 700}}>Must have: <br/><br/></span>
                <p>swift language - mid</p>
                <p>creating interfaces in SwiftUI and UIKit</p>
                <p>familiarity with Apple Human Interface Guidelines</p>
                <p>at least minimal knowledge of ARKit/ AVFoundation</p>
                <p>at least minimal knowledge of CoreML and working with real-time video processing</p>
                <p>basic knowledge of English</p>
                <span style={{fontWeight: 700}}>Nice to have: <br/><br/></span>
                <p>experience in publishing applications through TestFlight/ AppStore</p>
                <p>working with REST API</p>
                <p>coreData, UserDefaults, data storage on device</p>
                <p>in-App Purchase</p>
                <p>knowledge of Accelerate will be an added advantage</p>
            </>}
        />
    );
};

export default CareersIOSDevelopers;
